.header {
    background-color: $main-color;
    height: 80px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    >.container {
        height: 100%;
    }

    &_logo {
        width: 42px;

        &.bigger {
            width: 210px;
        }
    }

    &_nav {
        li {
            a {
                display: block;
                position: relative;
                padding: 0 17px;
                transition: $transition-main;
                font-size: 18px;

                // font-weight: 700;
                // color: #000;
                &::after {
                    position: absolute;
                    content: "";
                    width: 1px;
                    height: 35px;
                    background-color: #fff;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                }

                &:hover {
                    color: #000;
                }
            }

            &.active {
                a {
                    &::before {
                        position: absolute;
                        content: "";
                        width: 10px;
                        height: 10px;
                        background-image: url(../img/icon_nav-triangle.svg);
                        background-position: center center;
                        background-size: contain;
                        background-repeat: no-repeat;
                        top: -15px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }

            &:last-child {
                a {
                    &::after {
                        display: none;
                    }
                }
            }
        }

        &-m {
            position: fixed;
            background-color: #fff;
            width: 100vw;
            height: calc(100vh - 50px);
            overflow-y: auto;
            top: 50px;
            // left: 0;
            left: 100%;
            padding: 30px 20px;
            transition: 0.3s ease-in-out;

            &_list {
                max-width: 300px;
                width: 100%;
                margin: 0 auto;

                li {
                    a {
                        svg {
                            path {
                                fill: #979797;
                            }
                        }

                        &.active {
                            svg {
                                path {
                                    fill: #000;
                                }
                            }

                            span {
                                color: #000;
                            }

                            .header_nav-m_icon {
                                &.normal {
                                    display: none;
                                }

                                &.active {
                                    display: block;
                                }
                            }
                        }

                        &:last-child {
                            .header_nav-m_item {
                                border-bottom: 0 solid #000;
                            }
                        }
                    }
                }
            }

            &_item {
                padding: 18px 60px;
                border-bottom: 1px solid #C7C7C7;

                svg,
                img {
                    width: 30px;
                    margin-right: 20px;
                }

                span {
                    color: #979797;
                    font-size: 20px;
                }
            }

            &_icon {
                width: 30px !important;
                position: static !important;
                margin-right: 20px;

                &.active {
                    display: none;
                }

                &.user {
                    width: 22px !important;
                    margin-left: 5px;
                }
            }
        }

        &_user {
            width: 79px;
            height: 90px;
            background-image: url(../img/icon_user-gray.svg);
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            margin: 0 auto 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                color: #fff;
                font-size: 50px;
                font-weight: 700;
                display: none;
            }

            &.login {
                background-image: url(../img/icon_yellow-bg.svg);

                span {
                    display: block;
                }
            }
        }
    }

    &_icon {
        &_first {
            >li {
                position: relative;
                margin-left: 34px;

                img,
                svg {
                    width: 32px;

                    .icon_fill {
                        transition: $transition-main;
                    }

                    &:hover {
                        .icon_fill {
                            fill: #000;
                        }
                    }
                }

                &:hover {
                    .header_icon_second {
                        display: block;
                    }
                }

                a {
                    cursor: pointer;

                }
            }
        }

        &_second {
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            padding-top: 5px;
            display: none;

            >ul {
                width: 104px;
                border-radius: 10px;
                padding: 12px 20px;
                background-color: $main-deep;

                li {
                    margin-bottom: 12px;

                    a {
                        color: #fff;
                        transition: $transition-main;
                        display: block;
                        font-size: 14px;

                        &:hover {
                            color: $main-color;
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &_login {
            width: 32px;
            height: 38px;
            background-image: url(../img/icon_login.svg);
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            display: flex;
            align-items: center;
            justify-content: center;
            display: none;

            span {
                font-size: 18px;
                font-weight: 700;
                transform: translateY(-2px);
            }
        }

        &.login {
            .header_icon_notlogin {
                display: none;
            }

            .header_icon_login {
                display: flex;
                cursor: pointer;
            }
        }
    }

    &_menu {
        width: 25px;
        height: 25px;
        position: relative;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: contain;
            top: 0;
            left: 0;
            transition: $transition-main;
        }

        &_active {
            opacity: 0;
        }

        &.active {
            .header_menu {
                &_normal {
                    opacity: 0;
                }

                &_active {
                    opacity: 1;
                }
            }

            .header_nav-m {
                left: 0;
            }
        }
    }

    &_cart {
        position: relative;
        margin-left: 0 !important;
        padding-left: 15px !important;

        &_num {
            background-color: #F10045;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 14px;
            top: -10px;
            right: -10px;
            position: absolute;
        }
    }

    &_navText {
        padding: 0 15px;
        position: relative;
        margin: 0 !important;

        a {
            font-size: 18px;
        }

        &.noline {
            &::after {
                display: none;
            }
        }

        &::after {
            position: absolute;
            content: "";
            width: 1px;
            height: 35px;
            background-color: #fff;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

@media screen and (max-width:991px) {
    .header {
        >.container {
            max-width: none;
        }
    }
}

@media screen and (max-width:767px) {
    .header {
        height: 50px;

        &_logo {
            width: 26px;
            transform: translateY(2px);

            &.bigger {
                width: 150px;
            }
        }

        &_icon {
            &_first {
                >li {
                    margin-left: 22px;

                    svg {
                        // width: 30px;
                    }
                }
            }
        }

        &_cart {
            &_num {
                width: 20px;
                height: 20px;
                font-size: 12px;
                top: -5px;
            }
        }
    }

    main {
        padding-top: 50px;
    }
}