.popup {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    // align-items: center;
    justify-content: center;
    padding-top: 6rem;
    padding-bottom: 6rem;
    z-index: 10;
    overflow-y: auto;

    &_inner {
        background-color: #fff;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        height: fit-content;

        >.row {
            margin: 0;

            >div {
                padding: 0;
                position: static;
            }
        }
    }

    &-large {
        width: calc(100% - 40px);
        margin: 0 auto;
        max-width: 900px;
    }

    &_head {
        margin-bottom: 36px;
    }

    &_title {
        font-size: 24px;
    }

    &_close {
        width: 25px;

        img {
            width: 100%;
        }
    }

    &_content {
        background-color: #fff;
        border-radius: 10px;
        margin: 0 auto;
        overflow: hidden;
        position: relative;

        .row {
            margin: 0;

            >div {
                padding: 0;
                position: static;
            }
        }
    }

    &_filter {
        width: 90%;
        max-width: 600px;
        padding: 15px 20px;
    }

    &-medium {
        max-width: 900px;
        width: 100%;

        &_content {
            padding: 0 60px;
        }
    }

    &_logo {
        background-color: $main-color;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        img {
            width: 45%;
            position: static;
        }

        &.hint {
            img {
                width: 30%;
            }
        }
    }

    &_right {
        padding: 88px 30px;

        .popup-close {
            width: 25px;
            display: block;
            margin-left: auto;
            margin-bottom: 50px;
            transition: $transition-main;
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;

            &:hover {
                rect {
                    fill: #000;
                }
            }
        }

        &-hint {
            padding: 130px 70px;
        }
    }

    &_title {
        font-size: 20px;
        width: 100%;
        margin-bottom: 8px;
    }

    &_intro {
        margin-bottom: 35px;
    }

    &_form {
        input {
            margin-bottom: 16px;
        }

        button {
            font-size: 16px;
            width: 100%;
            padding: 12px 0;
            justify-content: center;
        }
    }

    &_hint {
        text-align: center;
        margin-top: 13px;
    }

    &-hint {
        &-box {
            h3 {
                font-size: 24px;
                text-align: center;
                margin: 0 0 5px;
            }

            p {
                text-align: center;
                margin-bottom: 50px;
            }
        }

        &_btn {
            &-alert {
                display: flex;
                align-items: center;
                justify-content: center;

                button {
                    margin: 0 7px;
                }
            }
        }
    }

    &-close {
        position: absolute;
        width: 25px;
        top: 20px;
        right: 20px;
    }

    &-common {
        &_title {
            font-size: 24px;
            margin: 0 0 30px;
        }

        &_content {
            width: 90%;
            max-width: 600px;
            padding: 15px 20px;
            background-color: #fff;
            border-radius: 10px;
            height: fit-content;
        }
    }
}

.login {
    &_tab {
        width: 100%;
        margin-bottom: 8px;

        li {
            margin-left: 15px;
            cursor: pointer;
        }
    }
}

.line_login {
    display: inline-block;
    width: 151px;
    height: 44px;
    background-image: url("../img/btn_login_base.png");
    background-size: cover;
    cursor: pointer;

    &:hover {
        background-image: url("../img/btn_login_hover.png");
    }

    &:active {
        background-image: url("../img/btn_login_press.png");
    }
}

.sigin {
    &_tab {
        &_item {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #BFBFBF;
            margin-right: 9px;
            margin-left: 0 !important;
            cursor: pointer;

            &:last-child {
                margin-right: 0;
            }

            &.active {
                background-color: $main-color;
            }
        }

        &_content {
            &_inner {
                display: none;

                &.active {
                    display: block;
                }

                &.tab-1 {
                    .popup_form {
                        // margin-top: 0;
                    }
                }
            }
        }
    }

    &_form {
        margin-top: 48px;

        &_step1 {
            margin: 30px auto 0;
            width: 100%;
            max-width: 300px;
            padding: 0 20px;
        }

        .input,
        .input-common {
            height: 50px !important;
        }

        .account_setting.term {
            border-bottom: 0;
            padding: 20px 0 0;
        }
    }

    &_input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 14px !important;

        &.birthday {
            .input {
                &.year {
                    width: 55%;
                }

                &.month {
                    width: 40%;
                }
            }
        }
    }

    &_btn-box {
        margin-top: 30px;

        button {
            width: fit-content;
            padding: 13px 63px;
            margin: 0 10px;
        }
    }

    &_check {
        margin-top: 20px;

        .check {
            width: 20%;
            margin: 0;
            margin-bottom: 20px;
        }
    }

    &_link {
        position: absolute;
        top: 2px;
        right: 0;
        // margin-top: 17px;
    }

    &_popup {
        &_right {

            // padding: 0 0 70px;
            >.popup-close {
                // position: static;
            }
        }

        &_head {
            background-color: $main-color;
            height: 50px;
            padding: 0 20px;

            img {
                width: 26px;
                position: static;
            }

            .popup-close {
                margin-bottom: 0;
                position: static;
                width: 20px;
            }
        }
    }

    &_title {
        // width: 100%;
        justify-content: flex-start;
    }
}

@media screen and (max-width:991px) {
    .popup {
        &_right {
            &-hint {
                padding: 80px 50px;
            }
        }
    }
}

@media screen and (max-width:800px) {
    .popup {
        &-hint {
            &_btn {
                &-alert {
                    button {
                        padding: 12px 25px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:767px) {
    .popup {
        &_logo {
            padding: 100px 0;

            img {
                width: 180px;
            }
        }
    }

    .sigin {
        &_input {
            &.birthday {
                margin-top: 14px;
            }
        }

        &_btn-box {
            button {
                width: calc(50% - 8px);
                padding: 10px 0;
            }
        }

        &_popup {
            &_right {
                padding: 0 0 70px;
            }

            &_content {
                padding: 35px 30px 0;
            }
        }

        &_title {
            width: 100%;
        }

        &_link {
            position: static;
            margin-top: 17px;
        }

        &_form {
            &_step1 {
                margin-top: 8px;
            }
        }
    }
}

@media screen and (max-width:575px) {
    .popup {
        &_right {
            padding: 40px 0;

            &-hint {
                padding: 50px 30px;
            }
        }

        &_title {
            width: fit-content;

            &.login {
                margin-bottom: 9px;
            }
        }

        &-medium {
            &_content {
                padding: 0 30px;
            }
        }

        &-hint {
            &_btn {
                &-alert {
                    justify-content: space-between !important;

                    button {
                        padding: 10px 0;
                        width: calc(50% - 8px);
                        margin: 0;
                        justify-content: center;
                    }
                }
            }
        }
    }

    .login_tab {
        width: fit-content;
        margin-bottom: 9px;
    }

    .sigin {
        &_popup {
            &_right {
                // padding: 40px 30px;
                padding: 0 0 30px;
            }
        }

        &_title {
            width: 100%;
        }

        &_link {
            position: static;
            width: 100%;
            margin-top: 17px;

            .login_tab {
                width: 100%;
            }
        }

        &_form {
            &_step1 {
                padding: 0;
            }
        }

        &_check {
            .check {
                width: 33.3333%;
            }
        }
    }
}