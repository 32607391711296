.footer {
    background-color: $main-color;
    padding-top: 43px;
    padding-bottom: 20px;
    .container {
        position: relative;
    }
    &_top {
        margin-bottom: 88px;
    }
    &_logo {
        width: 215px;
    }
    &_nav {
        width: 60%;
        h4 {
            font-size: 20px;
            color: #000;
            margin: 0 0 10px;
            font-weight: 500;
        }
        li {
            margin-bottom: 5px;
            &:last-child {
                margin-bottom: 0;
            }
            a {
                transition: $transition-main;
                &:hover {
                    color: #000;
                }
            }
        }
        &_item {
            width: fit-content;
            margin-left: auto;
        }
    }
    &_copyright {
        // text-align: center;
        margin: 0;
    }
    &_social {
        // position: absolute;
        right: 0;
        bottom: 0;
        a {
            display: block;
            margin-left: 16px;
            img {
                width: 40px;
            }
        }
    }
}

@media screen and (max-width:991px) {
    .footer {
        &_top {
            margin-bottom: 55px;
        }
        &_social {
            position: static;
            justify-content: center;
            margin-bottom: 20px;
            width: 100%;
            a {
                img {
                    width: 40px;
                }
            }
        }
        &_copyright {
            font-size: 14px;
            width: 100%;
            margin-bottom: 30px;
            text-align: center;
        }
        &_logo {
            width: 180px;
            display: block;
            margin: 0 auto 135px;
        }
        &_nav {
            width: 100%;
            margin: 0;
            >div {
                width: auto;
                max-width: inherit;
                flex: inherit;
            }
            &_item {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width:575px) {
    .footer {
        &_logo {
            width: 160px;
        }
    }
}

@media screen and (max-width: 370px) {
    .footer {
        >.container {
            padding-left: 15px;
            padding-right: 15px;
        }
        &_nav {
            >div {
                padding-left: 10px;
                padding-right: 10px;
            }
            h4 {
                font-size: 18px;
            }
            li {
                a {
                    font-size: 14px;
                }
            }
        }
    }
}