.home {
    &-content {
        .row {
            >div {
                padding-left: 35px;
                padding-right: 35px;
                border-left: 1px solid #979797;

                &.border-right {
                    border-right: 1px solid #979797;
                }

                &.border-top {
                    border-top: 1px solid #979797;
                    padding-top: 30px;
                }
            }
        }
    }

    &_explore {
        background-color: #A09E94;
        padding: 40px 0;
        margin-top: 46px;

        .title-common {
            padding: 0 35px;
        }

        h2 {
            color: #fff;
        }

        &_swiper {
            margin-top: 35px;
            color: #fff;

            .swiper-slide {
                padding: 0 105px;
            }

            .swiper-pagination-bullets {
                margin-top: 60px;
                margin-bottom: 0;
            }
        }

        &_pic {
            width: 100%;
            padding-bottom: 56.24%;
            position: relative;

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &_text {
            padding-left: 65px;
            color: #fff;

            h3 {
                font-size: 24px;
                font-weight: 500;
                margin: 0 0 10px;
            }

            p {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                margin: 0 0 40px;
            }
        }
    }

    &_item {
        padding-top: 30px;
        padding-bottom: 60px;
        border-bottom: 1px solid $gray-97;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 75px;
            border: 0;
        }

        .row {
            margin-left: -22px;
            margin-right: -22px;

            >div {
                padding-left: 22px;
                padding-right: 22px;
            }
        }

        .banner-content_pic {
            padding-bottom: 56%;
        }

        .banner-content {
            padding: 0;

            .swiper-button-prev {
                transform: translateY(calc(-50% - 16px));
            }

            .swiper-button-next {
                transform: translateY(calc(-50% - 16px)) scaleX(-1);
            }
        }

        @media screen and (max-width: 1199px) {
            .row {
                margin-left: -17px;
                margin-right: -17px;

                >div {
                    padding-left: 17px;
                    padding-right: 17px;

                    &:last-child {
                        display: block;
                    }
                }
            }
        }

        @media screen and (max-width: 767px) {
            border: 0;
            padding-bottom: 65px;
            padding-top: 0;

            .row {
                >div {
                    &:last-child {
                        display: block;
                    }

                    &:not(:last-child) {
                        margin-bottom: 20px;
                    }
                }
            }

            .banner-content {
                margin-bottom: 30px;
            }
        }
    }
}

@media screen and (max-width:991px) {
    .home {
        &-content {
            .row {
                >div {
                    &.border-md-right {
                        border-right: 1px solid #000;
                    }

                    &.border-md-top {
                        border-top: 1px solid #000;
                        padding-top: 37px;
                    }
                }
            }
        }

        &_explore {
            &_text {
                padding: 20px 0 0;

                p {
                    -webkit-line-clamp: 5;
                    margin-bottom: 20px;
                }

                .btn-common {
                    margin: 0 auto;
                }
            }

            .title-common {
                padding: 0;
            }
        }
    }
}

@media screen and (max-width:767px) {
    .home {
        &-content {
            .row {
                >div {
                    border: 0 !important;
                }
            }
        }

        &_explore {
            &_swiper {
                .swiper-slide {
                    padding: 0;
                }

                .swiper-button-next,
                .swiper-button-prev {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width:575px) {
    .home {
        &-content {
            .row {
                >div {
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
        }
    }
}