.content {
    &_filter {
        width: 300px;
        padding-right: 30px;
        padding-top: 54px;
    }
    &_list {
        width: calc(100% - 300px);
        padding-bottom: 70px;
        &_inner {
            padding: 50px 0 20px;
            border-top: 1px solid #000;
            border-bottom: 1px solid #000;
            margin-bottom: 30px;

        }
    }
    &_text {
        p {
            line-height: 1.8;
        }
    }
}

.article {
    &_content {
        p {
            line-height: 1.7;
            margin-bottom: 30px;
        }
        img {
            display: block;
            max-width: 550px;
            width: 100%;
            margin: 50px auto;
        }
    }
    &_inner {
        padding: 55px 0 60px;
    }
    &_title {
        width: calc(100% - 400px);
        margin-bottom: 45px;
        h1 {
            font-size: 24px;
            margin: 0 0 15px;
        }
        p {
            margin: 0;
        }
    }
    &_btn {
        // width: 400px;
        >img {
            width: 250px;
            // height: auto;
            margin-right: 10px;
        }
        .btn-favorite {
            height: auto;
        }
    }
    &_pagination {
        padding-bottom: 60px;
        padding-top: 20px;
        border-top: 1px solid #000;
        .btn-page {
            img {
                width: 10px;
                margin: 0;
            }
            &.prev {
                img {
                    transform: scaleX(-1);
                    margin-right: 15px;
                }
            }
            &.next {
                img {
                    margin-left: 15px;
                }
            }
        }
        .pagination {
            &_num {
                margin: 0 40px;
            }
        }
        &_bottom {
            span {
                display: block !important;
            }
        }
    }
}

.banner {
    &-inner {
        position: relative;
        width: 100%;
        padding-bottom: 38.75%;
        background: linear-gradient(0deg, #CDCDCD 17.08%, #868686 95.8%);
        &_pic {
            width: 50%;
            padding-bottom: calc(50% * 0.5638);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            box-shadow: 11px 27px 60px rgba(0, 0, 0, 0.42);
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
            }
        }
        .btn-icon {
            position: absolute;
            top: 20px;
            left: 20px;
            cursor: pointer;
            z-index: 10;
        }
        &_swiper {
            width: 100%;
            height: 100%;
            &-box {
                width: calc(50% + 30px);
                height: 100%;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
            .swiper-slide {
                width: calc(100% - 30px);
                padding-bottom: calc((100% - 30px) * 0.5638);
                position: relative;
                height: 0;
                top: 50%;
                transform: translateY(-50%);
                // box-shadow: 11px 27px 60px rgba(0, 0, 0, 0.42);
                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                }
            }
            .swiper-pagination-bullets {
                bottom: 6% !important;
                display: flex;
                align-items: center;
                justify-content: center;
                .swiper-pagination-bullet {
                    width: 18px;
                    height: 18px;
                    background-color: #fff;
                    margin: 0 7px !important;
                    opacity: 1;
                    &-active {
                        background-color: $main-color;
                    }
                }
            }
        }
    }
    &-video {
        padding-bottom: 40%;
        &_inner {
            position: absolute;
            width: 80%;
            height: 100%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            iframe {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
        }
    }
}

.inner {
    &-box {
        position: relative;
    }
    &_icon {
        padding-top: 15px;
    }
    &_content {
        padding: 37px 0 45px;
    }
}

.audio {
    padding: 56px 20px 10px;
    &_title {
        text-align: center;
        font-size: 24px;
        color: #000;
        margin-bottom: 48px;
    }
    &_content {
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
    }
    &_progress {
        &_bar {
            width: 100%;
            height: 5px;
            background-color: $gray-97;
            position: relative;
        }
        &_dot {
            position: absolute;
            width: 20px;
            height: 20px;
            background-color: $gray-97;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            border-radius: 50%;
            cursor: pointer;
        }
        &_text {
            text-align: right;
            margin-top: 7px;
            color: #000;
        }
    }
    &_icon {
        margin-bottom: 20px;
        &_center {
            margin: 0 83px;
            width: 45px;
            height: 45px;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                top: 0;
                left: 0;
                position: absolute;
                &.audio {
                    &_pause {
                        opacity: 0;
                    }
                }
            }
        }
    }
    &_speed {
        width: 90px;
        &.quick {
            transform: scaleX(-1);
        }
    }
    &_sound {
        position: relative;
        img {
            height: 31px;
        }
        &_progress {
            height: 5px;
            width: 103px;
            background-color: $gray-97;
            margin: 0 16px;
            position: relative;
        }
        &_dot {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: $gray-97;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            cursor: pointer;
        }
    }
    &_text {
        color: #D06BFF;
        text-align: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -20%;
        h3 {
            font-size: 50px;
            margin: 0;
            font-weight: normal;
        }
        p {
            font-size: 24px;
        }
    }
    &_style {
        padding: 50px 0;
        margin-top: 95px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        &_logo {
            width: 185px;
        }
        &_circle {
            width: 105px;
            height: 105px;
            border-radius: 50%;
            margin-right: 8%;
            transform: translateY(30px);
        }
        &.deep {
            background-color: #2F2411;
            .audio_style {
                &_circle {
                    background-color: #DE8C11;
                }
            }
            background-image: url(../img/audio_style.svg);
        }
    }
}

.term {
    padding-top: 60px;
    .title-content {
        margin-bottom: 60px;
    }
    h1 {
        // margin-bottom: 30px;
    }
    &_item {padding-bottom: 100px;}
    @media screen and (max-width: 767px) {
        .title-content {
            margin-bottom: 30px;
        }
    }
}

.social-share {
    &_item {
        padding: 1px 10px;
        border-radius: 3px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        svg, img {
            width: 13px;
        }
        span {
            font-size: 12px;
            margin-left: 3px;
        }
        &.fb-share {
            padding: 3px 10px;
            span {
                font-size: 13px;
            }
        }
    }
    &_fb {  
        background-color: #3C71F1;
    }
    &_line {
        background-color: #29C75B;
        padding: 3px 10px;
        span {
            font-size: 14px;
        }
        img {
            width: 15px;
        }
    }
}

.title_article {
    width: calc(100% - 145px);
}

.service {
    padding-top: 50px;
    padding-bottom: 70px;
    &_content {
        max-width: 950px;
        width: 100%;
        padding: 0 25px;
        margin: 0 auto;
    }
    &_inform {
        max-width: 750px;
        margin: 0 auto;
        &_item {
            background-color: #EEEEEE;
            padding: 30px 40px;
            display: flex;
            align-items: center;
            border-radius: 10px;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        &_icon {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            background-color: #fff;
            width: 42px;
            height: 42px;
            img {
                width: 60%;
            }
        }
        &_text {
            width: calc(100% - 42px);
            padding-left: 20px;
            h3 {
                font-size: 16px;
                font-weight: normal;
                margin: 0;
                color: #000;
            }
            p {
                font-size: 16px;
                color: $main-deep;
                margin: 0;
            }
        }
    }
    &_contact {
        max-width: 750px;
        margin: 0 auto;
        .input-common {
            background-color: #eee;
        }
        .account_setting_input {
            margin-bottom: 25px !important;
        }
        textarea {
            padding: 15px 30px;
            background-color: #eee;
            width: 100%;
            height: 260px;
            border-radius: 10px;
            color: $main-deep;
        }
        .btn-common {
            padding: 11px 63px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 50px auto 18px;
        }
    }
    @media screen and (max-width: 575px) {
        &_content {
            padding: 0;
        }
        &_inform {
            &_item {
                padding: 25px 15px;
                p {
                    font-size: 14px;
                }
            }
        }
    }
    @media screen and (max-width: 365px) {
        &_inform {
            &_item {
                padding: 20px 15px;
            }
            &_text {
                padding-left: 10px;
                p {
                    font-size: 12px;
                }
            }
        }
    }
}

.about {
    padding-bottom: 100px;
    &_content {
        // padding-top: 35px;
    }
    &_item {
        margin-bottom: 100px;
    }
    &_text {
        padding-right: 45px;
        h3 {
            font-size: 30px;
            font-weight: 600;
            margin-bottom: 20px;
            color: $main-deep;
        }
        p {
            color: $main-deep;
            margin: 0;
        }
    }
    &_pic {
        width: 100%;
        padding-bottom: 56%;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            top: 0;
            left: 0;
            position: absolute;
        }
    }
    @media screen and (max-width: 991px) {
        .title-content {
            margin-bottom: 30px;
        }
        &_text {
            padding: 0 0 45px;
            h3 {
                font-size: 20px;
            }
        }
    }
}

@media screen and (max-width:991px) {
    .content {
        &_filter {
            width: 250px;
        }
        &_list {
            width: calc(100% - 250px);
        }
    }
    .audio {
        &_text {
            display: none;
        }
    }
    .article {
        &_title {
            width: 100%;
            margin-bottom: 15px;
        }
        &_btn {
            justify-content: flex-end;
            width: 100%;
            margin-bottom: 37px;
        }
    }
}

@media screen and (max-width:767px)  {
    .title_article {
        width: 100%;
    }
}

@media screen and (max-width:767px) {
    .content {
        &_list {
            width: 100%;
            &_inner {
                border-top: 0;
                margin-bottom: 20px;
                padding-top: 34px;
            }
        }
    }
    .banner {
        &-inner {
            padding: 0;
            background: transparent;
            &_pic {
                position: relative;
                width: 100%;
                padding-bottom: 56.38%;
                transform: none;
                left: 0;
                top: 0;
                box-shadow: none;
            }
            .btn-back {
                display: none;
            }
            &_swiper {
                padding-bottom: 25px;
                &-box {
                    width: 100%;
                    // padding-bottom: 56.38%;
                    position: relative;
                }
                .swiper-slide {
                    width: 100%;
                    padding-bottom: 56.38%;
                    transform: none;
                }
                .swiper-pagination-bullets {
                    bottom:0px !important;
                    .swiper-pagination-bullet {
                        background-color: #BFBFBF;
                        width: 10px;
                        height: 10px;
                        margin: 0 4px !important;
                        &-active {
                            background-color: $main-color;
                        }
                    }
                }
            }
        }
        &-video {
            position: relative;
            padding-bottom: 56.38%;
            &_inner {
                width: 100%;
            }
            iframe {
                width: 100%;
            }
        }
    }
    .audio {
        padding: 43px 20px 10px;
        &_icon {
            margin-top: 5px;
            margin-bottom: 37px;
            &_center {
                width: 24px;
                height: 24px;
                margin: 0 45px;
            }
        }
        &_speed {
            width: 48px;
        }   
        &_sound {
            img {
                // height: 17px;
            }
        }
        &_style {
            margin-top: 60px;
            padding: 0;
            background-size: cover;
            background-position: right center;
            .container {
                position: relative;
                height: 100%;
                padding-top: 45px;
                padding-bottom: 105px;
            }
            &_circle {
                width: 80px;
                height: 80px;
                position: absolute;
                right: 0;
                bottom: 30px;
                top: inherit;
                transform: none;
            }
            &.deep {
                background-image: url(../img/audio_style-m.svg);
            }
        }
    }
    .article {
        &_inner {
            padding: 30px 0 50px;
        }
        &_btn {
            justify-content: flex-start;
            .btn-favorite {
                display: none;
            }
        }
        &_pagination {
            &_top {
                margin-bottom: 0;
                padding-top: 20px;
                padding-bottom: 20px;
            }
        }
    }
}